
const AK = 'Z2MZR3RVTUheBBPSwXjIGI4SEByUYEmF' // 百度地图 AK

const SCROLLWHEELZOOM = true // 是否开启滚轮缩放

const MARKERICON = item => {
  let type = ''
  if (item.ol === 1) type = 'online'
  else type = 'not-online'

  return {
    url: require('@/assets/map/' + type + '.png'),
    size: { width: 38, height: 70 },
  }
}

const LABELSTYLE = {
  color: '#00E5FF',
  fontSize: '14px',
  border: 'none',
  background: 'rgba(0,0,0,0.5)',
  padding: '3px 8px',
  borderRadius: '19px',
} // 地图点标签样式
const LABELOFFSET = {
  width: 0,
  height: 60,
} // 地图点标签位置

export const MAP_STYLE = {
  AK,
  SCROLLWHEELZOOM,
  MARKERICON,
  LABELSTYLE,
  LABELOFFSET,
}
